import { Component, EventEmitter, Output, OnInit, HostListener} from '@angular/core';
import { DataService } from '../data.service';
import {Router} from '@angular/router'
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';


export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})

export class CarouselComponent implements OnInit {

selectedIndex : number;
isTrue: boolean = true;

  @Output() passIndex = new EventEmitter<object>();
  
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {

    if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
     this.getNext();
    }

    if (event.keyCode === KEY_CODE.LEFT_ARROW) {
      this.getPrev();
    }
  }

  showSlide() {
    // const slideArray = this.dataService.reactProjects.map(data => {
    //   if (this.router.url == '/react')
    //   return [data];
    // })
    // return slideArray;
  }

changeIndex(i : number){
  this.selectedIndex = i;
  this.passObj();
}

getNext() {
  if (this.selectedIndex < 2) {
    this.selectedIndex += 1;
    this.passObj();
  }
}
getPrev() {
  if (this.selectedIndex > 0) {
   this.selectedIndex -= 1;
   this.passObj();
  }
}

passObj() {
  this.passIndex.emit({ index: this.selectedIndex, title: this.dataService.reactProjects[this.selectedIndex].title, description: this.dataService.reactProjects[this.selectedIndex].description, video: this.sanitizer.bypassSecurityTrustResourceUrl(this.dataService.reactProjects[this.selectedIndex].video) });
}

  constructor(public dataService: DataService, private router: Router, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.selectedIndex = 1;
    this.passObj();
  }
 
}
