import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-views',
  templateUrl: './views.component.html',
  styleUrls: ['./views.component.scss']
})
export class ViewsComponent implements OnInit {

  constructor() { }

  react = 'React';
  angular = 'Angular';
  express = 'Express';
  cplusplus = 'C++';

  ngOnInit() {
  }

}
