import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-angualr-view',
  templateUrl: './angualr-view.component.html',
  styleUrls: ['./angualr-view.component.scss']
})
export class AngualrViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
