import { Component, OnInit, SimpleChange, OnChanges } from '@angular/core';
import { DataService } from '../data.service';
import { DomSanitizer } from '@angular/platform-browser';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  query,
  // ...
} from '@angular/animations';

@Component({
  selector: 'app-react-view',
  templateUrl: './react-view.component.html',
  styleUrls: ['./react-view.component.scss'],
  styles: [`
      :host {
        width: 100%
      }
    `],
    animations: [
      trigger(
        'myInsertRemoveTrigger',
        [
          transition(
            ':enter', [
              style({transform: 'translateX(100%)', opacity: 0}),
              animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
            ]
          ),
          transition(
            ':leave', [
              style({transform: 'translateX(0)', 'opacity': 1}),
              animate('500ms', style({transform: 'translateX(-100%)', opacity: 0}))
            ]
          )
        ])
    ]
})


export class ReactViewComponent implements OnInit {
  passIndex : number;
  selectedIndex: number;

  title : string;
  description: string;
  featureVideo: any;
  isValid = true;

  eventCatcher($event){
    this.selectedIndex = $event.index;
    this.title = $event.title;
    this.description = $event.description;
    this.featureVideo = $event.video;

  }
  constructor(public dataService: DataService, private sanitizer: DomSanitizer) {
}

  ngOnInit() {

  }

}


  // getData() {
  //   console.log("give me the react object data",this.dataService.reactProjects )
  //   return this.dataService.reactProjects;
  // }
  // featured(){
  //   const currentslide = this.sanitizer.bypassSecurityTrustResourceUrl(this.dataService.reactProjects[this.selectedIndex].video);
  //   return currentslide;
  // }

  // featuredTitle($event){
  //   console.log("title", $event)
  //   const currentslide = this.dataService.reactProjects[this.selectedIndex].title;
  //   return currentslide
  // }

  // featuredDescription(){
  //     const currentslide = this.dataService.reactProjects[this.selectedIndex].description;
  //     return currentslide
  // }