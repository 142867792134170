import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ViewsComponent } from './views/views.component';
import { ReactViewComponent } from './react-view/react-view.component';

import { RouterModule } from '@angular/router';
import { AngualrViewComponent } from './angualr-view/angualr-view.component';
import { ExpressViewComponent } from './express-view/express-view.component';
import { CarouselComponent } from './carousel/carousel.component';
import { HttpClientModule } from '@angular/common/http';
import { CplusplusViewComponent } from './cplusplus-view/cplusplus-view.component';


@NgModule({
  declarations: [
    AppComponent,
    ViewsComponent,
    ReactViewComponent,
    AngualrViewComponent,
    ExpressViewComponent,
    CarouselComponent,
    CplusplusViewComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule.forRoot([
      { path: `react`, component: ReactViewComponent },
      { path: `angular`, component: AngualrViewComponent },
      { path: `express`, component: ExpressViewComponent },
      { path: `c++`, component: CplusplusViewComponent }
    ])
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
