import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class DataService {
    reactProjects = [
        {
          title: "test",
          description: "test",
          video: 'https://www.youtube.com/embed/MXJLk7YOISQ',
          img: 'https://cnet3.cbsistatic.com/img/lVcr_knN8zG1N8Fd7TU-axAbn3c=/1092x0/2019/07/11/c89a4fa1-33e2-46a6-82a5-5b3212845e19/d9vjqrlwkaeq9wy.jpg'
        },
        {
          title: "test",
          description: "test",
          video: 'https://www.youtube.com/embed/5M4dR-eWp1Y',
          img: "https://cnet3.cbsistatic.com/img/lVcr_knN8zG1N8Fd7TU-axAbn3c=/1092x0/2019/07/11/c89a4fa1-33e2-46a6-82a5-5b3212845e19/d9vjqrlwkaeq9wy.jpg"
        },
        {
          title: "test",
          description: "test",
          video: 'https://www.youtube.com/embed/p6ULvPFPLIY',
          img: "https://cnet3.cbsistatic.com/img/lVcr_knN8zG1N8Fd7TU-axAbn3c=/1092x0/2019/07/11/c89a4fa1-33e2-46a6-82a5-5b3212845e19/d9vjqrlwkaeq9wy.jpg"

        },
      ];
}
